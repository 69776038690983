/**
 * Component: Footer
 */

.footer {
    background-color: rgba(0, 0, 0, 0.1);
    color: var(--colour-pure-white);
    height: 100vh;
    position: relative;
}

.footer .footer__caption {
    display: inline-block;
    vertical-align: middle;
}

.footer .footer__center {
    align-items: center;
    display: flex;
    height: 100%;
    width: 100%;
}

.footer .footer__credit {
    bottom: var(--space-sm);
    left: var(--space-sm);
    line-height: 1;
    margin: 0;
    position: absolute;
}

.footer .footer__container {
    height: 100%;
    position: relative;
}

.footer .footer__icon {
    color: var(--colour-grey-moth-red);
    display: inline-block;
    margin-right: var(--space-xs); /* 10px */
    vertical-align: middle;
}

.footer .footer__link {
    color: inherit;
    display: block;
    line-height: 1;
    margin: var(--space-sm) 0; /* 20px 0 */
    text-decoration: none;
}

.footer .footer__title > p {
    margin: 0;
}

/* Responsive */

@media (--md) {
    .footer .footer__credit {
        bottom: var(--space-md);
        left: var(--space-md);
    }
}