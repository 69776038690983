/**
 * Component: Module Full Video
 */

.module-full-video {
    margin: var(--space-md) 0; /* 30px 0 */
}

.module-full-video .module-full-video__button {
    color: var(--colour-grey-moth-yellow);
    font-size: 0;
    left: 50%;
    letter-spacing: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
}

.js-module-full-video--playing.module-full-video .module-full-video__button {
    display: none;
}

.module-full-video .module-full-video__caption {
    margin-top: 0.8823529412rem; /* 15px */
}

.module-full-video .module-full-video__copy {
    margin: 0;
}

.module-full-video .module-full-video__figure {
    font-size: 0;
    letter-spacing: 0;
    line-height: 0;
    margin: 0 0 var(--space-md) 50%;
    position: relative;
    transform: translateX(-50%);
    width: 100vw;
}

.module-full-video .module-full-video__text > div > *:first-child {
    margin-top: 0;
}

.module-full-video .module-full-video__text > div > *:last-child {
    margin-bottom: 0;
}

.module-full-video .module-full-video__video {
    width: 100%;
}

.module-full-video .module-full-video__title {
    margin-bottom: var(--space-sm);
}

/* Responsive */

@media (--md) {
    .module-full-video .module-full-video__caption {
        margin-top: 1.1764705882rem; /* 20px */
    }
}