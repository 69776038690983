/**
 * Component: Header
 */

.header {
    background-color: rgba(0, 0, 0, 0.1);
    color: var(--colour-pure-white);
    height: 100vh;
    transition: 180ms color ease-out;
    position: relative;
    z-index: 2;
}

.header.header--mini,
.header.header--project {
    height: 3.5294117647rem; /* 60px */
}

.header.header--mini,

.header.js-header--active,
.header.js-header--sticky {
    color: var(--colour-pure-black);
}

.header .header__button {
    color: inherit;
    font-size: 0;
    letter-spacing: 0;
    line-height: 0;
    padding: 1.4705882353rem var(--space-sm); /* 25px 15px */
    position: absolute;
    right: 0;
    top: 0;
}

.header.js-header--active .header__button {
    padding: 1.1764705882rem var(--space-sm); /* 20px 15px */
}

.header .header__button .icon--cross {
    display: none;
}

.header .header__button .icon--navigation {
    display: block;
}

.header.js-header--active .header__button .icon--cross {
    display: block;
}

.header.js-header--active .header__button .icon--navigation {
    display: none;
}

.header .header__inner {
    position: fixed;
    width: 100%;
}

.header.header--mini .header__inner,
.header.header--project .header__inner {
    height: 3.5294117647rem; /* 60px */
}

.header:not(.header--mini, .header--project) .header__inner {
    background-color: rgba(238, 238, 238, 0);
    left: 0;
    height: 100%;
    top: 0;
    transition: 180ms background-color ease-out;
    will-change: height;
}

.header.header--mini .header__inner,
.header:not(.header--mini, .header--project).js-header--sticky .header__inner {
    background-color: var(--colour-pale-grey);
}

.header.header--project .header__inner {
    background-color: var(--colour-grey-moth-teal);
}

.header .header__link {
    color: inherit;
    display: block;
    transform: scale(1); /* height 30px */
}

.header .header__logo {
    font-size: 0;
    left: 50%;
    letter-spacing: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
}

.header.js-header--active .header__logo {
    display: none;
}

.header .header__menu-logo {
    display: none;
    font-size: 0;
    left: 50%;
    letter-spacing: 0;
    line-height: 0;
    position: absolute;
    top: var(--space-sm); /* 15px */
    transform: translate(-50%, 0);
}

.header.js-header--active .header__menu-logo {
    display: block;
}

.header .header__scroll {
    bottom: var(--space-md);
    color: var(--colour-grey-moth-yellow);
    font-size: 0;
    left: 50%;
    letter-spacing: 0;
    line-height: 1;
    position: absolute;
    transform: translateX(-50%);
    transition:
        180ms transform ease-out,
        180ms opacity ease-out;
}

.header.js-header--shrinking .header__scroll {
    opacity: 0;
}

.header.header--mini .header__scroll,
.header.header--project .header__scroll {
    display: none;
}

/* Responsive */

@media (--md) {
    .header {
        height: 100vh;
    }

    .header.header--mini,
    .header.header--project {
        height: 4.7058823529rem; /* 80px */
    }

    .header .header__button {
        padding: var(--space-md) 1.7647058824rem; /* 40px 30px */
    }

    .header.js-header--active .header__button {
        padding: var(--space-md) 1.7647058824rem; /* 40px 30px */
    }

    .header.header--mini .header__inner,
    .header.header--project .header__inner {
        height: 4.7058823529rem; /* 80px */
    }
}

@media (--lg) {
    .header.header--project {
        position: fixed;
        transition: opacity 240ms ease-out;
    }

    .header.js-header--active {
        color: var(--colour-pure-white);
    }

    .header.js-header--sticky {
        color: var(--colour-pure-black);
    }

    .js-project-video--playing .header {
        opacity: 0;
    }

    .header .header__button {
        display: none;
    }

    .header.header--project .header__inner {
        background-color: transparent;
    }

    .header:not(.header--mini, .header--project) .header__link {
        transform: scale(3.1); /* height 124px */
    }

    .header.js-header--active .header__logo {
        display: block;
    }

    .header.js-header--active .header__menu-logo {
        display: none;
    }
}
