/**
 * Base: Typography
 */

@font-face {
    font-display: swap;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 100;
    src: url('/asset/font/WorkSans-Light.woff2') format('woff2');
}

@font-face {
    font-display: swap;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 500;
    src: url('/asset/font/WorkSans-Medium.woff2') format('woff2');
}

html {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    color: var(--colour-pure-black);
    font-family: 'Work Sans', sans-serif;
    font-size: 17px;
    font-weight: 100;
}

h1,
h2,
h3,
h4,
h5,
h6,
body {
    font-family: inherit;
    font-size: 1rem;
    font-style: normal;
    font-weight: 100;
    margin: 0;
}

address {
    font-style: normal;
}

.type-style-1 {
    font-family: var(--type-style-1-family);
    font-size: var(--type-style-1-size);
    font-weight: var(--type-style-1-weight);
    letter-spacing: var(--type-style-1-letter-spacing);
    line-height: var(--type-style-1-line-height);
    text-transform: var(--type-style-1-text-transform);
}

.type-style-2 {
    font-family: var(--type-style-2-family);
    font-size: var(--type-style-2-size);
    font-weight: var(--type-style-2-weight);
    letter-spacing: var(--type-style-2-letter-spacing);
    line-height: var(--type-style-2-line-height);
    text-transform: var(--type-style-2-text-transform);
}

.type-style-3 {
    font-family: var(--type-style-3-family);
    font-size: var(--type-style-3-size);
    font-weight: var(--type-style-3-weight);
    letter-spacing: var(--type-style-3-letter-spacing);
    line-height: var(--type-style-3-line-height);
    text-transform: var(--type-style-3-text-transform);
}

.type-style-4 {
    font-family: var(--type-style-4-family);
    font-size: var(--type-style-4-size);
    font-weight: var(--type-style-4-weight);
    letter-spacing: var(--type-style-4-letter-spacing);
    line-height: var(--type-style-4-line-height);
    text-transform: var(--type-style-4-text-transform);
}

.type-style-5 {
    font-family: var(--type-style-5-family);
    font-size: var(--type-style-5-size);
    font-weight: var(--type-style-5-weight);
    letter-spacing: var(--type-style-5-letter-spacing);
    line-height: var(--type-style-5-line-height);
    text-transform: var(--type-style-5-text-transform);
}

.type-style-6 {
    font-family: var(--type-style-6-family);
    font-size: var(--type-style-6-size);
    font-weight: var(--type-style-6-weight);
    letter-spacing: var(--type-style-6-letter-spacing);
    line-height: var(--type-style-6-line-height);
    text-transform: var(--type-style-6-text-transform);
}
