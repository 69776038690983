/**
 * Component: Module Video Hover
 */

.module-video-hover {
    margin: var(--space-md) 0; /* 30px 0 */
}

.module-video-hover .module-video-hover__button {
    color: var(--colour-grey-moth-yellow);
    font-size: 0;
    left: 50%;
    letter-spacing: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
}

.js-module-video-hover--playing.module-video-hover .module-video-hover__button {
    display: none;
}

.module-video-hover .module-video-hover__copy {
    margin: 0;
}

.module-video-hover .module-video-hover__figure {
    font-size: 0;
    height: 100%;
    left: 0;
    letter-spacing: 0;
    line-height: 0;
    margin: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.module-video-hover .module-video-hover__image {
    width: 100%;
}

.module-video-hover .module-video-hover__link {
    color: inherit;
    display: block;
    text-decoration: none;
}

.module-video-hover .module-video-hover__media {
    font-size: 0;
    letter-spacing: 0;
    line-height: 0;
    margin: 0 0 var(--space-md) 0;
    position: relative;
}

.module-video-hover .module-video-hover__text > div > *:first-child {
    margin-top: 0;
}

.module-video-hover .module-video-hover__text > div > *:last-child {
    margin-bottom: 0;
}

.module-video-hover .module-video-hover__video {
    display: none;
    height: 100%;
    left: 0;
    object-fit: cover;
    object-position: center;
    position: absolute;
    top: 0;
    width: 100%;
}

.module-video-hover .module-video-hover__link.module-video-hover__link--active .module-video-hover__video {
    display: block;
}

.module-video-hover .module-video-hover__title {
    margin-bottom: var(--space-sm);
}