/**
 * Trump: Plyr Override
 */

.plyr--video {
    --plyr-badge-border-radius: 0;
    --plyr-color-main: var(--colour-grey-moth-yellow);
    --plyr-control-icon-size: 0.5882352941rem; /* 10px */
    --plyr-control-radius: 0;
    --plyr-range-fill-background: var(--colour-grey-moth-yellow);
    --plyr-range-thumb-background: var(--colour-pure-white);
    --plyr-range-thumb-shadow: none;
    --plyr-range-track-height: 0.1176470588rem; /* 2px */
    --plyr-video-control-background-hover: transparent;
    --plyr-video-control-color-hover: var(--colour-grey-moth-yellow);
    --plyr-video-control-color: var(--colour-pure-white);
    --plyr-video-controls-background: transparent;
    --plyr-video-range-track-background: var(--colour-pure-white);
}

/* controls container */

.plyr--video .plyr__controls {
    margin: 0 auto; /* 0 auto */
    padding: 0.8823529412rem; /* 15px */
    max-width: 84.7058823529rem; /* 1440px */;
}

.plyr--video.plyr--hide-controls .plyr__controls {
    opacity: 1;
    transform: none;
}

.plyr--video .plyr__controls:after {
    content: '';
    display: none;
    width: calc((100%/12) * 1);
    height: 10px;
    padding: 0 60px;
}

.plyr--video .plyr__controls:before {
    content: '';
    display: none;
    width: calc((100%/12) * 1);
    height: 10px;
    padding: 0 60px;
}

/* module video */

.module-video .plyr--video .plyr__controls {
    display: none;
    padding: 1.1764705882rem; /* 20px */
}

.module-video.js-module-video--playing .plyr--video .plyr__controls {
    display: flex;
}

.module-video .plyr--video .plyr__controls:after,
.module-video .plyr--video .plyr__controls:before {
    content: none;
}

/* module full video */


.module-full-video .plyr--video .plyr__controls {
    display: none;
    padding: 1.1764705882rem; /* 20px */
}

.module-full-video.js-module-full-video--playing .plyr--video .plyr__controls {
    display: flex;
}

.module-full-video .plyr--video .plyr__controls:after,
.module-full-video .plyr--video .plyr__controls:before {
    content: none;
}

/* buttons */

.plyr__controls__item.plyr__control[data-plyr="fullscreen"] {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' class='icon--fullscreen'%3E%3Cg fill='none' fill-rule='evenodd' stroke='#ffffff' stroke-width='2'%3E%3Cpath d='M11 7.667V11H7.667M4.333 11H1V7.667M1 4.333V1h3.333M7.667 1H11v3.333'/%3E%3C/g%3E%3C/svg%3E");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 1.0588235294rem; /* 18px */
    height: 1.0588235294rem; /* 18px */
    margin-left: 0.2941176471rem; /* 5px */
    padding-left: 0;
    width: 1.0588235294rem; /* 18px */
}

.plyr__controls__item.plyr__control[data-plyr="fullscreen"]:hover {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' class='icon--fullscreen'%3E%3Cg fill='none' fill-rule='evenodd' stroke='#e8c91f' stroke-width='2'%3E%3Cpath d='M11 7.667V11H7.667M4.333 11H1V7.667M1 4.333V1h3.333M7.667 1H11v3.333'/%3E%3C/g%3E%3C/svg%3E");
}

.plyr__controls__item.plyr__control[data-plyr="play"] {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 14' class='icon--play'%3E%3Cpath fill='none' fill-rule='evenodd' stroke='#e8c91f' stroke-width='2' d='M11 7L1 12V2z'/%3E%3C/svg%3E");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 1.0588235294rem; /* 18px */
    height: 1.0588235294rem; /* 18px */
    margin-right:1.0588235294rem; /* 18px */
    padding-left: 0;
    width: 1.0588235294rem; /* 18px */
}

.plyr__controls__item.plyr__control[data-plyr="play"]:hover {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 14' class='icon--play'%3E%3Cpath fill='none' fill-rule='evenodd' stroke='#e8c91f' stroke-width='2' d='M11 7L1 12V2z'/%3E%3C/svg%3E");
}

.plyr--playing .plyr__controls__item.plyr__control[data-plyr="play"] {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 12' class='icon--pause'%3E%3Cg fill='none' fill-rule='evenodd' stroke='#ffffff' stroke-width='2'%3E%3Cpath d='M1 1h4v10H1zM9 1h4v10H9z'/%3E%3C/g%3E%3C/svg%3E");
}

.plyr--playing .plyr__controls__item.plyr__control[data-plyr="play"]:hover {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 12' class='icon--pause'%3E%3Cg fill='none' fill-rule='evenodd' stroke='#e8c91f' stroke-width='2'%3E%3Cpath d='M1 1h4v10H1zM9 1h4v10H9z'/%3E%3C/g%3E%3C/svg%3E");
}

.plyr--video .plyr__controls__item.plyr__progress__container {
    margin: 0 0.5882352941rem; /* 0 10px */
    padding: 0;
}

.plyr--video .plyr__progress {
    left: 0;
    margin-right: 0;
}

.plyr__controls .plyr__controls__item.plyr__time {
    margin: 0 0.8823529412rem; /* 0 15px */
    padding: 0;
}

/* thumb */

.plyr--video input[type=range]::-webkit-slider-thumb {
    border-radius: 0;
    width: 0.1176470588rem; /* 2px */
}

.plyr--video input[type=range]::-moz-range-thumb {
    border-radius: 0;
    width: 0.1176470588rem; /* 2px */
}

.plyr--video input[type=range]::-ms-thumb {
    border-radius: 0;
    width: 0.1176470588rem; /* 2px */
}

.plyr--video input[type=range]::-webkit-slider-runnable-track {
    border-radius: 0;
}

.plyr--video input[type=range]::-moz-range-track {
    border-radius: 0;
}

.plyr--video input[type=range]::-ms-track {
    border-radius: 0;
}

/* icons */

.plyr--video .plyr__controls__item.plyr__control > svg {
    display: none;
}

/* video */

.plyr--video .plyr__video-wrapper:after {
    background: rgba(0, 0, 0, 0.1);
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transition: opacity 240ms ease-out;
    width: 100%;
}

.js-project-video--playing .plyr--video.plyr--hide-controls .plyr__controls {
    opacity: 0;
}

.js-project-video--playing .plyr--video .plyr__video-wrapper:after {
    opacity: 0;
}

/* Responsive */

@media (--md) {
    .plyr--video .plyr__controls {
        padding: 1.7647058824rem; /* 30px */
    }
}


@media (--lg) {
    .plyr--video {
        --plyr-control-icon-size: 0.8823529412rem; /* 15px */
    }

    .plyr--video .plyr__controls {
        padding: 2.3529411765rem; /* 40px */
        transition: opacity 240ms ease-out;
    }

    .plyr--video .plyr__controls:after,
    .plyr--video .plyr__controls:before {
        display: block;
    }

    .plyr--playing .plyr--video .plyr__controls,
    .project-video.js-project-video--show-panel .plyr--video .plyr__controls {
        opacity: 0;
    }
}