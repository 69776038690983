/**
 * Component: Project Video
 */

.project-video .project-video__button {
    text-align: right;
}

.project-video .project-video__button-open {
    color: var(--colour-pure-white);
    line-height: 1;
    margin-top: var(--space-sm); /* 10px */
}

.project-video .project-video__caption {
    display: inline-block;
    vertical-align: middle;
}

.project-video .project-video__credit {
    margin-bottom: var(--space-sm);
    margin-top: var(--space-lg);
}

.project-video .project-video__details {
    list-style: none;
    margin: 0;
    padding: 0;
}

.project-video .project-video__figure {
    font-size: 0;
    letter-spacing: 0;
    line-height: 0;
    margin: 0;
}

.project-video .project-video__icon {
    display: inline-block;
    margin-right: var(--space-xs); /* 10px */
    vertical-align: middle;
}

.project-video.project-video--commissioned .project-video__icon,
.project-video.project-video--commissions .project-video__icon {
    color: var(--colour-grey-moth-yellow);
}

.project-video.project-video--original .project-video__icon,
.project-video.project-video--originals .project-video__icon {
    color: var(--colour-grey-moth-blue);
}

.project-video .project-video__icon-close {
    color: var(--colour-pure-white);
    display: none;
}

.project-video .project-video__navigation {
    background-color: var(--colour-grey-moth-teal);
    font-size: 0;
    letter-spacing: 0;
    line-height: 0;
}

.project-video .project-video__navigation-container {
    justify-content: space-between;
}

.project-video .project-video__next,
.project-video .project-video__prev {
    color: var(--colour-pure-white);
    display: inline-block;
    padding: 1.7647058824rem 0.5882352941rem; /* 30px 10px */
    text-decoration: none;
}

.project-video .project-video__next {
    float: right;
}

.project-video .project-video__next-figure,
.project-video .project-video__prev-figure {
    display: none;
}

.project-video .project-video__overlay {
    color: var(--colour-pure-white);
    display: none;
    left: 0;
    position: fixed;
    bottom: 8.8235294118rem; /* 150px */
    transition: opacity 240ms ease-out;
    width: 100%;
}

.project-video .project-video__panel {
    color: var(--colour-pure-white);
    background-color: var(--colour-grey-moth-teal);
    min-height: calc(100vh - 15.8823529412rem); /* 100% - (fig210px + head60px) */
    padding: 0 0 1.7647058824rem 0; /* 0 0 30px 0 */
    position: relative;
}

/* Responsive */

@media (--md) {
    .project-video .project-video__next,
    .project-video .project-video__prev {
        padding: 1.7647058824rem 0.88235294124rem; /* 30px 15px */
    }

    .project-video .project-video__panel {
        min-height: calc(100vh - 4.7058823529rem); /* 100% - (head80px) */
    }
}

@media (--lg) {
    .project-video {
        height: 100vh;
        overflow: hidden;
    }

    .project-video .project-video__credit {
        margin-top: 0;
    }

    .project-video .project-video__figure {
        height: 100vh;
    }

    .project-video .project-video__icon-close {
        display: block;
        line-height: 1;
    }

    .project-video .project-video__navigation {
        background-color: transparent;
        left: 50%;
        position: absolute;
        top: 50%;
        transition: opacity 240ms ease-out;
        transform: translate(-50%, -50%);
        width: 100%;
    }

    .js-project-video--playing .project-video .project-video__navigation,
    .project-video.js-project-video--show-panel .project-video__navigation {
        opacity: 0;
    }

    .project-video .project-video__next,
    .project-video .project-video__prev {
        padding: 1.1764705882rem; /* 20px */
    }

    .project-video .project-video__next--null,
    .project-video .project-video__prev--null {
        opacity: 0;
    }

    .project-video .project-video__next:hover,
    .project-video .project-video__prev:hover {
        color: var(--colour-grey-moth-yellow);
    }

    .project-video .project-video__next-caption,
    .project-video .project-video__prev-caption {
        color: var(--colour-pure-white);
    }

    .project-video .project-video__next-figure,
    .project-video .project-video__prev-figure {
        display: none;
        margin: 0 0 2.6470588235rem 0; /* 0 0 45px 0 */
        position: absolute;
        bottom: 0;
        width: 18.2352941176rem; /* 310px */
    }

    .project-video .project-video__next-figure {
        margin-left: -18.2352941176rem; /* -310px */
    }

    .project-video .project-video__next:hover .project-video__next-figure,
    .project-video .project-video__prev:hover .project-video__prev-figure {
        display: block;
    }

    .project-video .project-video__next-img,
    .project-video .project-video__prev-img {
        margin-bottom: 0.5882352941rem; /* 10px */
        width: 100%;
    }

    .project-video .project-video__overlay {
        display: block;
    }

    .js-project-video--playing .project-video .project-video__overlay,
    .project-video.js-project-video--show-panel .project-video__overlay {
        opacity: 0;
    }

    .project-video .project-video__panel {
        background-color: rgba(32, 63, 75, 0.9);
        min-height: auto;
        padding: 2.3529411765rem 0; /* 40px 0 */
        transition: transform 240ms ease-out;
        transform: translateY(50%);
    }

    .project-video.js-project-video--show-panel .project-video__panel {
        transform: translateY(-100%);
    }
}