/**
 * Component: Module Full Image
 */

.module-full-image {
    margin: var(--space-md) 0; /* 30px 0 */
}

.module-full-image.module-full-image--no-mb {
    margin-bottom: 0;
}

.module-full-image .module-full-image__caption {
    margin-top: 0.8823529412rem; /* 15px */
}

.module-full-image .module-full-image__container {
    padding: 0 0.8823529412rem; /* 0 15px */
}

.module-full-image .module-full-image__copy {
    margin: 0;
}

.module-full-image .module-full-image__figure {
    font-size: 0;
    letter-spacing: 0;
    line-height: 0;
    margin: 0 0 var(--space-md) 50%;
    position: relative;
    transform: translateX(-50%);
    width: 100vw;
}

.module-full-image.module-full-image--no-mb .module-full-image__figure {
    margin: 0 0 0 50%;
}

.module-full-image .module-full-image__text > div > *:first-child {
    margin-top: 0;
}

.module-full-image .module-full-image__text > div > *:last-child {
    margin-bottom: 0;
}

.module-full-image .module-full-image__image {
    width: 100%;
}

.module-full-image .module-full-image__title {
    margin-bottom: var(--space-sm);
}

/* Responsive */

@media (--md) {
    .module-full-image.module-full-image--no-mb {
        margin-bottom: 0;
    }

    .module-full-image .module-full-image__caption {
        margin-top: 1.1764705882rem; /* 20px */
    }

    .module-full-image .module-full-image__container {
        padding: 0 1.2941176471rem; /* 0 22 px */
    }
}

@media (--lg) {
    .module-full-image .module-full-image__container {
        padding: 0 1.5882352941rem; /* 0 27px */
    }
}