/**
 * Component: Module Project Listing
 */

.module-project-listing {
    margin: var(--space-md) 0 var(--space-sm) 0; /* 30px 0 15px 0 */
}

.module-project-listing .module-project-listing__caption {
    margin-top: var(--space-sm); /* 15px */
}

.module-project-listing .module-project-listing__column {
    margin: var(--space-md) 0; /* 30px 0 */
    padding: 0 0.4411764706rem; /* 0 7.5px */
    width: 100%;
}

.module-project-listing > .module-project-listing__container:first-child .module-project-listing__column:first-child {
    margin: 0;
    width: 100%;
}

.module-project-listing .module-project-listing__container {
    align-items: baseline;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.module-project-listing .module-project-listing__figure {
    font-size: 0;
    letter-spacing: 0;
    line-height: 1;
    margin: 0;
}

.module-project-listing .module-project-listing__filters {
    list-style: none;
    margin: 0.5882352941rem 0 0 0; /* 10px 0 0 0 */
    padding: 0;
}

.module-project-listing .module-project-listing__filter-item {
    display: inline;
}

.module-project-listing .module-project-listing__title {
    margin: 0;
    width: 90%;
}

.module-project-listing .module-project-listing__icon {
    display: inline-block;
    margin-right: var(--space-xs); /* 10px */
    vertical-align: top;
}

.module-project-listing .module-project-listing--commissioned .module-project-listing__icon,
.module-project-listing .module-project-listing--commissions .module-project-listing__icon,
.module-project-listing .module-project-listing__filter-item--commissioned .module-project-listing__icon,
.module-project-listing .module-project-listing__filter-item--commissions .module-project-listing__icon {
    color: var(--colour-grey-moth-yellow);
}

.module-project-listing .module-project-listing--original .module-project-listing__icon,
.module-project-listing .module-project-listing--originals .module-project-listing__icon,
.module-project-listing .module-project-listing__filter-item--original .module-project-listing__icon,
.module-project-listing .module-project-listing__filter-item--originals .module-project-listing__icon {
    color: var(--colour-grey-moth-blue);
}

.module-project-listing .module-project-listing__image {
    width: 100%;
}

.module-project-listing .module-project-listing__label {
    cursor: pointer;
    font-size: 0;
    letter-spacing: 0;
    line-height: 1;
}

.module-project-listing .module-project-listing__label-text {
    display: inline-block;
    line-height: 1.7647058824rem; /* 30px */
    margin-right: var(--space-xs); /* 10px */
    text-transform: capitalize;
    vertical-align: top;
}

.module-project-listing .module-project-listing__input:checked + .module-project-listing__label .module-project-listing__label-text {
    font-weight: 400;
}

.module-project-listing .module-project-listing__link {
    color: inherit;
    display: block;
    text-decoration: none;
}

.module-project-listing .module-project-listing__media {
    font-size: 0;
    letter-spacing: 0;
    line-height: 1;
    margin: 0;
    overflow: hidden;
    position: relative;
}

.module-project-listing .module-project-listing__subtitle {
    display: block;
    margin-left: 1.5882352941rem; /* 27px */
}

.module-project-listing .module-project-listing__title {
    display: inline-block;
    vertical-align: top;
}

.module-project-listing .module-project-listing__video {
    display: none;
    height: 101%;
    left: 0;
    object-fit: cover;
    object-position: center;
    position: absolute;
    top: 0;
    width: 100%;
}

/* Responsive */

@media (--md) {
    .module-project-listing .module-project-listing__column {
        padding: 0 0.8823529412rem; /* 0 15px */
        width: 50%;
    }

    .module-project-listing .module-project-listing__caption {
        margin-top: var(--space-sm);
    }

    .module-project-listing .module-project-listing__filters {
        margin: 1.1764705882rem 0 0 0; /* 20px 0 0 0 */
    }

    .module-project-listing .module-project-listing__media:hover .module-project-listing__video {
        display: block;
    }
}

@media (--lg) {
    .module-project-listing .module-project-listing__column {
        padding: 0 1.1764705882rem; /* 0 20px */
    }

    .module-project-listing .module-project-listing__column:nth-child(8n+1) {
        width: calc((100%/12) * 8);
    }

    .module-project-listing .module-project-listing__column:nth-child(8n+2) {
        width: calc((100%/12) * 4);
    }

    .module-project-listing .module-project-listing__column:nth-child(8n+3) {
        width: calc((100%/12) * 6);
    }

    .module-project-listing .module-project-listing__column:nth-child(8n+4) {
        width: calc((100%/12) * 6);
    }

    .module-project-listing .module-project-listing__column:nth-child(8n+5) {
        width: calc((100%/12) * 4);
    }

    .module-project-listing .module-project-listing__column:nth-child(8n+6) {
        width: calc((100%/12) * 8);
    }

    .module-project-listing .module-project-listing__column:nth-child(8n+7) {
        width: calc((100%/12) * 6);
    }

    .module-project-listing .module-project-listing__column:nth-child(8n+8) {
        width: calc((100%/12) * 6);
    }
}