/**
 * Object: Grid
 */

/* Container */

.container {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto; /* 0 auto */
    max-width: 84.7058823529rem; /* 1440px */;
    padding: 0 0.4411764706rem; /* 0 7.5px */
}

.container.container--no-x-padding {
    padding: 0;
}

.container.container--sm-no-x-padding {
    padding: 0;
}

/* Responsive */

@media (--md) {
    .container,
    .container.container--sm-no-x-padding {
        padding: 0 0.8823529412rem; /* 0 15px */
    }
}

@media (--lg) {
    .container,
    .container.container--sm-no-x-padding {
        padding: 0 1.1764705882rem; /* 0 20px */
    }

    .container.container--lg-no-x-padding {
        padding: 0;
    }
}

/* Grid */

.col {
    flex-grow: 0;
    flex-shrink: 0;
    padding: 0 0.4411764706rem; /* 0 7.5px */
}

.col.col--sm-no-x-padding {
    padding: 0; /* 0 */
}

.col-right {
    flex-grow: 0;
    flex-shrink: 0;
    padding: 0 0.4411764706rem; /* 0 7.5px */
}

.col-right.col-right--sm-no-x-padding {
    padding: 0; /* 0 */
}

.col-1 { width: calc(1 / 12 * 100%); }
.col-2 { width: calc(2 / 12 * 100%); }
.col-3 { width: calc(3 / 12 * 100%); }
.col-4 { width: calc(4 / 12 * 100%); }
.col-5 { width: calc(5 / 12 * 100%); }
.col-6 { width: calc(6 / 12 * 100%); }
.col-7 { width: calc(7 / 12 * 100%); }
.col-8 { width: calc(8 / 12 * 100%); }
.col-9 { width: calc(9 / 12 * 100%); }
.col-10 { width: calc(10 / 12 * 100%); }
.col-11 { width: calc(11 / 12 * 100%); }
.col-12 { width: 100%; }

.not-empty {
    min-height: 1px;
}

@media (--sm) {
    .sm-col-1 { width: calc(1 / 12 * 100%); }
    .sm-col-2 { width: calc(2 / 12 * 100%); }
    .sm-col-3 { width: calc(3 / 12 * 100%); }
    .sm-col-4 { width: calc(4 / 12 * 100%); }
    .sm-col-5 { width: calc(5 / 12 * 100%); }
    .sm-col-6 { width: calc(6 / 12 * 100%); }
    .sm-col-7 { width: calc(7 / 12 * 100%); }
    .sm-col-8 { width: calc(8 / 12 * 100%); }
    .sm-col-9 { width: calc(9 / 12 * 100%); }
    .sm-col-10 { width: calc(10 / 12 * 100%); }
    .sm-col-11 { width: calc(11 / 12 * 100%); }
    .sm-col-12 { width: 100%; }

    .sm-width-auto { width: auto; }
}

@media (--md) {
    .col,
    .col.col--sm-no-x-padding {
        padding: 0 0.8823529412rem; /* 0 15px */
    }

    .col-right,
    .col-right.col-right--sm-no-x-padding {
        padding: 0 0.8823529412rem; /* 0 15px */
    }

    .md-col-1 { width: calc(1 / 12 * 100%); }
    .md-col-2 { width: calc(2 / 12 * 100%); }
    .md-col-3 { width: calc(3 / 12 * 100%); }
    .md-col-4 { width: calc(4 / 12 * 100%); }
    .md-col-5 { width: calc(5 / 12 * 100%); }
    .md-col-6 { width: calc(6 / 12 * 100%); }
    .md-col-7 { width: calc(7 / 12 * 100%); }
    .md-col-8 { width: calc(8 / 12 * 100%); }
    .md-col-9 { width: calc(9 / 12 * 100%); }
    .md-col-10 { width: calc(10 / 12 * 100%); }
    .md-col-11 { width: calc(11 / 12 * 100%); }
    .md-col-12 { width: 100%; }

    .md-width-auto { width: auto; }
}

@media (--lg) {
    .col,
    .col.col--sm-no-x-padding {
        padding: 0 1.1764705882rem; /* 0 20px */
    }

    .col-right,
    .col-right.col-right--sm-no-x-padding {
        padding: 0 1.1764705882rem; /* 0 20px */
    }

    .lg-col-1 { width: calc(1 / 12 * 100%); }
    .lg-col-2 { width: calc(2 / 12 * 100%); }
    .lg-col-3 { width: calc(3 / 12 * 100%); }
    .lg-col-4 { width: calc(4 / 12 * 100%); }
    .lg-col-5 { width: calc(5 / 12 * 100%); }
    .lg-col-6 { width: calc(6 / 12 * 100%); }
    .lg-col-7 { width: calc(7 / 12 * 100%); }
    .lg-col-8 { width: calc(8 / 12 * 100%); }
    .lg-col-9 { width: calc(9 / 12 * 100%); }
    .lg-col-10 { width: calc(10 / 12 * 100%); }
    .lg-col-11 { width: calc(11 / 12 * 100%); }
    .lg-col-12 { width: 100%; }

    .lg-width-auto { width: auto; }
}
