/**
 * Setting: Space
 */

/* root foot size 17px */

/* Breakpoints: XS & SM */

:root {
    --space-lg: 3.5294117647rem; /* 60px */
    --space-md: 1.7647058824rem; /* 30px */
    --space-sm: 0.8823529412rem; /* 15px */
    --space-xs: 0.5882352941rem; /* 10px */
}

/* Breakpoints: MD & LG & XL */

@media (--md) {
    :root {
        --space-lg: 4.7058823529rem; /* 80px */
        --space-md: 2.3529411765rem; /* 40px */
        --space-sm: 1.1764705882rem; /* 20px */
        --space-xs: 0.5882352941rem; /* 10px */
    }
}
