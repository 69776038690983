/**
 * Base: Icon
 */

.icon--cross {
    height: 0.9411764706rem; /* 16px */
}

.icon--down {
    width: 1.2941176471rem; /* 22px */
}

.icon--left {
    width: 0.7058823529rem; /* 12px */
}

.icon--logo {
    height: 1.7647058824rem; /* 30px */
}

.icon--navigation {
    height: 0.5294117647rem; /* 9px */
}

.icon--play {
    width: 1.7647058824rem; /* 30px */
}

.icon--right {
    width: 0.7058823529rem; /* 12px */
}

.icon--slash {
    height: 1.7647058824rem; /* 30px */
}

/* Responsive */

@media (--md) {
    .icon--logo {
        height: 2.3529411765rem; /* 40px */
    }
}

@media (--lg) {
    .icon--down {
        width: 1.4705882353rem; /* 25px */
    }

    .icon--logo {
        height: 2.3529411765rem; /* 40px */
    }

    .icon--left {
        width: 0.9411764706rem; /* 16px */
    }

    .icon--right {
        width: 0.9411764706rem; /* 16px */
    }
}