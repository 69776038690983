/**
 * Component: Module
 */

.modules {
    margin: var(--space-md) 0; /* 30px 0 */
    width: 100%;
}

.modules.modules--no-mb {
    margin: var(--space-md) 0 0 0; /* 30px 0 0 0 */
}