/**
 * Setting: Colour
 */

:root {
    --colour-black-tint: rgba(0, 0, 0, 0.1);
    --colour-pale-grey: rgba(238, 238, 238, 1);
    --colour-pure-black: rgba(39, 39, 39, 1);
    --colour-pure-white: rgba(255, 255, 255, 1);
    --colour-soft-black: rgba(39, 39, 39, 1);

    --colour-grey-moth-blue: rgba(120, 203, 234, 1);
    --colour-grey-moth-red: rgba(208, 98, 69, 1);
    --colour-grey-moth-teal: rgba(32, 63, 75, 1);
    --colour-grey-moth-yellow: rgba(232, 201, 31, 1);
}