/**
 * Component: Navigation
 */

.navigation {
    background-color: var(--colour-grey-moth-yellow);
    height: 100vh;
    padding: 0 0.8823529412rem; /* 0 15px */
    transform: translate3d(-100%, 0, 0);
    transition: 180ms transform ease-out;
}

.header.js-header--active .navigation {
    transform: translate3d(0, 0, 0);
}

.navigation .navigation__link {
    color: inherit;
    font-family: var(--type-style-1-family);
    font-size: var(--type-style-1-size);
    font-weight: var(--type-style-1-weight);
    letter-spacing: var(--type-style-1-letter-spacing);
    line-height: var(--type-style-1-line-height);
    text-decoration: none;
    text-transform: capitalize;
}

.navigation .navigation__list {
    list-style: none;
    margin: 0;
    padding: 0;
}

.navigation .navigation__list:first-child {
    padding: 5.8823529412rem 0 0 0; /* 100px 0 0 0 */
}

.navigation .navigation__list-item {
    margin: 1.1764705882rem 0; /* 20px 0 */
}

.navigation .navigation__list-item:first-child {
    margin-top: 0;
}

/* Responsive */

@media (--md) {
    .navigation {
        padding: 0 1.7647058824rem; /* 0 30px */
    }

    .navigation .navigation__list:first-child {
        padding: 7.0588235294rem 0 0 0; /* 120px 0 0 0 */
    }
}

@media (--lg) {
    .navigation {
        background-color: transparent;
        display: flex;
        height: 100%;
        justify-content: space-between;
        margin: 0 auto;
        max-width: 84.7058823529rem; /* 1440px */;
        overflow: hidden;
        padding: 1.4705882353rem var(--space-md); /* 25px 40px */
        transform: none;
        transition: none;
    }

    .header.js-header--active .navigation {
        transform: none;
    }

    .navigation .navigation__list:first-child {
        padding: 0;
    }

    .navigation .navigation__link {
        font-family: var(--type-style-6-family);
        font-size: var(--type-style-6-size);
        font-weight: var(--type-style-6-weight);
        letter-spacing: var(--type-style-6-letter-spacing);
        line-height: var(--type-style-6-line-height);
        text-transform: var(--type-style-6-text-transform);
    }

    .navigation .navigation__list {
        display: flex;
        justify-content: space-between;
    }

    .navigation .navigation__list-item {
        margin-bottom: 0;
        margin-top: 0;
    }

    .navigation .navigation__list:first-child .navigation__list-item {
        margin-right: var(--space-sm); /* 20px */
    }

    .navigation .navigation__list:last-child .navigation__list-item {
        margin-left: var(--space-sm); /* 20px */
    }
}