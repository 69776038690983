/**
 * Component: Module Image
 */

.module-image {
    margin: var(--space-md) 0; /* 30px 0 */
}

.module-image .module-image__copy {
    margin: 0;
}

.module-image .module-image__figure {
    font-size: 0;
    letter-spacing: 0;
    line-height: 0;
    margin: 0 0 var(--space-md) 0;
    position: relative;
}

.module-image .module-image__image {
    width: 100%;
}

.module-image .module-image__text > div > *:first-child {
    margin-top: 0;
}

.module-image .module-image__text > div > *:last-child {
    margin-bottom: 0;
}

.module-image .module-image__title {
    margin-bottom: var(--space-sm);
}