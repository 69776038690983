/**
 * Component: Module 2 Col
 */

.module-2-col {
    margin: var(--space-md) 0; /* 30px 0 */
}

.module-2-col .module-2-col__caption {
    display: inline-block;
    vertical-align: middle;
}

.module-2-col .module-2-col__icon {
    color: var(--colour-grey-moth-red);
    display: inline-block;
    margin-right: var(--space-xs); /* 10px */
    vertical-align: middle;
}

.module-2-col .module-2-col__link {
    color: inherit;
    display: block;
    line-height: 1;
    margin: 1.1764705882rem 0 0 0; /* 20px 0 0 0 */
    text-decoration: none;
}

.module-2-col .module-2-col__text {
    margin: 0;
}

.module-2-col .module-2-col__title {
    margin-bottom: var(--space-md); /* 30px */
}

@media (--md) {
    .module-2-col .module-2-col__title {
        margin-bottom: 0;
    }
}