/**
 * Component: Module Dual CTA
 */

.module-dual-cta {
    margin: var(--space-md) 0; /* 30px 0 */
}

.module-dual-cta .module-dual-cta__caption {
    bottom: var(--space-sm); /* 15px */
    color: var(--colour-pure-white);
    left: var(--space-sm); /* 15px */
    line-height: 1;
    margin: 0;
    position: absolute;
    text-transform: capitalize;
}

.module-dual-cta .module-dual-cta__colour {
    display: none;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.module-dual-cta .module-dual-cta__figure {
    margin: 0;
}

.module-dual-cta .module-dual-cta__image {
    width: 100%;
}

.module-dual-cta .module-dual-cta__link {
    color: inherit;
    display: block;
    text-decoration: none;
    overflow: hidden;
    position: relative;
}

.module-dual-cta .module-dual-cta__media {
    font-size: 0;
    letter-spacing: 0;
    line-height: 0;
    position: relative;
}

.module-dual-cta .module-dual-cta__media:first-child {
    margin-bottom: var(--space-md); /* 30px */
}

.module-dual-cta .module-dual-cta__media--active {
    z-index: 1;
}

.module-dual-cta .module-dual-cta__video {
    display: none;
    height: 100%;
    left: 0;
    object-fit: cover;
    object-position: center;
    position: absolute;
    top: 0;
    width: 100%;
}

@media (--md) {
    .module-dual-cta .module-dual-cta__media:first-child {
        margin-bottom: 0;
    }
}

@media (--lg) {
    .module-dual-cta {
        margin-bottom: 13.5294117647rem; /* 230px */
    }

    .module-dual-cta .module-dual-cta__caption {
        bottom: var(--space-md); /* 40px */
        left: var(--space-md); /* 40px */
    }

    .module-dual-cta .module-dual-cta__media:first-child {
        margin-right: -33.333333333%; /* calc((100%/12) * -4); rounding issue during transform */
    }

    .module-dual-cta .module-dual-cta__media:last-child {
        transform: translateY(13.5294117647rem); /* 230px */
    }

    .module-dual-cta .module-dual-cta__media.module-dual-cta__media--not-active .module-dual-cta__colour {
        display: block;
    }

    .module-dual-cta .module-dual-cta__media:hover .module-dual-cta__video {
        display: block;
    }
}