/**
 * Setting: Type
 */

/* root foot size 17px */

/* XS & SM */

:root {
    /* Type Style 1 */
    --type-style-1-family: 'Work Sans', sans-serif;
    --type-style-1-letter-spacing: -0.02941176471rem; /* -0.5px */
    --type-style-1-line-height: 1.09375; /* 35px */
    --type-style-1-size: 1.8823529412rem; /* 32px */
    --type-style-1-text-transform: none;
    --type-style-1-weight: 400;

    /* Type Style 2 */
    --type-style-2-family: 'Work Sans', sans-serif;
    --type-style-2-letter-spacing: -0.02941176471rem; /* -0.5px */
    --type-style-2-line-height: 1.3076923077; /* 34px */
    --type-style-2-size: 1.5294117647rem; /* 26px */
    --type-style-2-text-transform: none;
    --type-style-2-weight: 400;

    /* Type Style 3 */
    --type-style-3-family: 'Work Sans', sans-serif;
    --type-style-3-letter-spacing: -0.02941176471rem; /* -0.5px */
    --type-style-3-line-height: 1.3076923077; /* 34px */
    --type-style-3-size: 1.5294117647rem; /* 26px */
    --type-style-3-text-transform: none;
    --type-style-3-weight: 100;

    /* Type Style 4 */
    --type-style-4-family: 'Work Sans', sans-serif;
    --type-style-4-letter-spacing: 0;
    --type-style-4-line-height: 1.4705882353; /* 25px */
    --type-style-4-size: 1rem; /* 17px */
    --type-style-4-text-transform: none;
    --type-style-4-weight: 400;

    /* Type Style 5 */
    --type-style-5-family: 'Work Sans', sans-serif;
    --type-style-5-letter-spacing: 0;
    --type-style-5-line-height: 1.4705882353; /* 25px */
    --type-style-5-size: 1rem; /* 17px */
    --type-style-5-text-transform: none;
    --type-style-5-weight: 100;

    /* Type Style 6 */
    --type-style-6-family: 'Work Sans', sans-serif;
    --type-style-6-letter-spacing: 0.02941176471rem; /* 0.5px */
    --type-style-6-line-height: 1.6666666667; /* 25px */
    --type-style-6-size: 0.8823529412rem; /* 15px */
    --type-style-6-text-transform: uppercase;
    --type-style-6-weight: 400;
}

/* MD & LG & XL */

@media (--md) {
    :root {
        /* Type Style 1 */
        --type-style-1-family: 'Work Sans', sans-serif;
        --type-style-1-letter-spacing: -0.04411764706; /* -0.75px */
        --type-style-1-line-height: 1.1111111111; /* 60px */
        --type-style-1-size: 3.1764705882rem; /* 54px */
        --type-style-1-text-transform: none;
        --type-style-1-weight: 400;

        /* Type Style 2 */
        --type-style-2-family: 'Work Sans', sans-serif;
        --type-style-2-letter-spacing: -0.02941176471rem; /* -0.5px */
        --type-style-2-line-height: 1.25; /* 40px */
        --type-style-2-size: 1.8823529412rem; /* 32px */
        --type-style-2-text-transform: none;
        --type-style-2-weight: 400;

        /* Type Style 3 */
        --type-style-3-family: 'Work Sans', sans-serif;
        --type-style-3-letter-spacing: -0.02941176471rem; /* -0.5px */
        --type-style-3-line-height: 1.25; /* 40px */
        --type-style-3-size: 1.8823529412rem; /* 32px */
        --type-style-3-text-transform: none;
        --type-style-3-weight: 100;

        /* Type Style 4 */
        --type-style-4-family: 'Work Sans', sans-serif;
        --type-style-4-letter-spacing: 0;
        --type-style-4-line-height: 1.5; /* 30px */
        --type-style-4-size: 1.1764705882rem; /* 20px */
        --type-style-4-text-transform: none;
        --type-style-4-weight: 400;

        /* Type Style 5 */
        --type-style-5-family: 'Work Sans', sans-serif;
        --type-style-5-letter-spacing: 0;
        --type-style-5-line-height: 1.5; /* 30px */
        --type-style-5-size: 1.1764705882rem; /* 20px */
        --type-style-5-text-transform: none;
        --type-style-5-weight: 100;

        /* Type Style 6 */
        --type-style-6-family: 'Work Sans', sans-serif;
        --type-style-6-letter-spacing: 0.02941176471rem; /* 0.5px */
        --type-style-6-line-height: 1.875; /* 30px */
        --type-style-6-size: 0.9411764706rem; /* 16px */
        --type-style-6-text-transform: uppercase;
        --type-style-6-weight: 400;
    }
}
