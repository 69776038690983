/**
 * Component: Background Video
 */

.background-video {
    background-color: var(--colour-pure-black);
    font-size: 0;
    height: 100vh;
    left: 0;
    letter-spacing: 0;
    line-height: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: -1;
}

.background-video .background-video__iframe,
.background-video .background-video__video {
    height: 100%;
    object-fit: cover;
    object-position: center;
    width: 100%;
}

.background-video .background-video__overlay {
    background-color: var(--colour-black-tint);
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}