/**
 * Component: Module Standfirst
 */

.module-standfirst .module-standfirst__subtitle {
    margin: 1.1764705882rem 0 0 0; /* 20px 0 0 0 */
}

.module-standfirst .module-standfirst__subtitle > p {
    margin: 0;
}

.module-standfirst .module-standfirst__title {
    margin: var(--space-md) 0 0 0; /* 30px 0 0 0 */
}

/* Responsive */

@media (--sm) {
    .module-standfirst .module-standfirst__subtitle {
        margin: 0 0 3.5294117647rem 0; /* 0 0 60px 0 */
    }

    .module-standfirst .module-standfirst__title {
        margin: 3.5294117647rem 0 1.1764705882rem 0; /* 60px 0 20px 0 */
    }
}

@media (--md) {
    .module-standfirst .module-standfirst__subtitle {
        margin: 0 0 4.7058823529rem 0; /* 0 0 80px 0 */
    }

    .module-standfirst .module-standfirst__title {
        margin: var(--space-md) 0 1.1764705882rem 0; /* 40px 0 20px 0 */
    }
}