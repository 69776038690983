/**
 * Component: Module Video
 */

.module-video {
    margin: var(--space-md) 0; /* 30px 0 */
}

.module-video .module-video__button {
    color: var(--colour-grey-moth-yellow);
    font-size: 0;
    left: 50%;
    letter-spacing: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
}

.js-module-video--playing.module-video .module-video__button {
    display: none;
}

.module-video .module-video__copy {
    margin: 0;
}

.module-video .module-video__figure {
    font-size: 0;
    letter-spacing: 0;
    line-height: 0;
    margin: 0 0 var(--space-md) 0;
    position: relative;
}

.module-video .module-video__text > div > *:first-child {
    margin-top: 0;
}

.module-video .module-video__text > div > *:last-child {
    margin-bottom: 0;
}

.module-video .module-video__video {
    width: 100%;
}

.module-video .module-video__title {
    margin-bottom: var(--space-sm);
}