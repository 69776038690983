/**
 * Generic: Tap Highlight
 */

a,
button,
input,
textarea {
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-text-size-adjust: none;
    -webkit-touch-callout: none;
}