/**
 * Trump: Hide
 */

.access-hide {
    clip-path: inset(1px);
    clip: rect(1px 1px 1px 1px);
    clip: rect(1px, 1px, 1px, 1px);
    display: block;
    height: 1px;
    overflow: hide;
    position: absolute;
    white-space: nowrap;
    width: 1px;
}

.hide {
    display: none !important;
}

@media (--xs) {
    .hide--xs {
        display: none !important;
    }
}

@media (--sm) {
    .hide--sm {
        display: none !important;
    }
}

@media (--md) {
    .hide--md {
        display: none !important;
    }
}

@media (--lg) {
    .hide--lg {
        display: none !important;
    }
}

@media (--xl) {
    .hide--xl {
        display: none !important;
    }
}