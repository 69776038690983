/**
 * Component: Module Text
 */

/* Responsive */

.module-text {
    margin: var(--space-md) 0; /* 30px 0 */
}

.module-text .module-text__figure {
    margin: 0;
}

.module-text .module-text__text > div > *:first-child {
    margin-top: 0;
}

.module-text .module-text__text > div > *:last-child {
    margin-bottom: 0;
}

.module-text .module-text__text h3 {
    font-family: var(--type-style-4-family);
    font-size: var(--type-style-4-size);
    font-weight: var(--type-style-4-weight);
    letter-spacing: var(--type-style-4-letter-spacing);
    line-height: var(--type-style-4-line-height);
    text-transform: var(--type-style-4-text-transform);
}

.module-text .module-text__text p {
    font-family: var(--type-style-5-family);
    font-size: var(--type-style-5-size);
    font-weight: var(--type-style-5-weight);
    letter-spacing: var(--type-style-5-letter-spacing);
    line-height: var(--type-style-5-line-height);
    text-transform: var(--type-style-5-text-transform);
}

.module-text .module-text__text a {
    border-bottom: 1px solid var(--colour-grey-moth-red);
    color: inherit;
    text-decoration: none;
}

.module-text .module-text__text ol,
.module-text .module-text__text ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.module-text .module-text__text ol li,
.module-text .module-text__text ul li {
    font-family: var(--type-style-5-family);
    font-size: var(--type-style-5-size);
    font-weight: var(--type-style-5-weight);
    letter-spacing: var(--type-style-5-letter-spacing);
    line-height: var(--type-style-5-line-height);
    text-transform: var(--type-style-5-text-transform);
}

.module-text .module-text__text ol li:before,
.module-text .module-text__text ul li:before {
    content: '—';
    margin-right: 0.5882352941rem; /* 10px */
}

.module-text .module-text__text ol li > *,
.module-text .module-text__text ul li > * {
    display: inline;
    margin: 0;
}

/* Responsive */

@media (--sm) {
    .module-text.module-text--sm-no-mt {
        margin-top: 0;
    }
}