/**
 * Trump: Show
 */

.show--xs,
.show--sm,
.show--md,
.show--lg,
.show--xl {
    display: none !important;
}

@media (--xs) {
    .show--xs {
        display: block !important;
    }
}

@media (--sm) {
    .show--sm {
        display: block !important;
    }
}

@media (--md) {
    .show--md {
        display: block !important;
    }
}

@media (--lg) {
    .show--lg {
        display: block !important;
    }
}

@media (--xl) {
    .show--xl {
        display: block !important;
    }
}