/**
 * Generic: Box Sizing
 */

html {
    box-sizing: border-box;
}

*,
*:after,
*:before {
    box-sizing: inherit;
}